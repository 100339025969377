import Link from 'next/link';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { formatDate, getBlogLinkUrl } from '@/helpers/helpers';

import ArticlesReadMoreTrigger from '@/components/ArticlesReadMoreTrigger';

import LeftArrowDecal from '@/components/LeftArrowDecal';

import * as styles from './index.module.scss';

const ArticleCardsWithImages = ({ articles = [], headline = 'Recent Articles', showCta = false }) => {
  const _renderBackgroundImage = (src) => {
    return { background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 100%), url(${src}) no-repeat center center / cover` };
  };

  return (
    <div className={`${styles['articles-cards-with-images-container']}`}>
      <LeftArrowDecal />
      <div className={`container ${styles['container']}`}>
        <h2>{headline}</h2>
        <div className={styles['recent-articles-wrapper']}>
          {articles.map((article) => (
            <Link href={getBlogLinkUrl(article)} key={article.id} className={styles['article-card-with-image']}>
              {article.hero_src && (
                <div className={styles['image-container']}>
                  <LazyLoadImage src={article.hero_src} alt={article.h1} />
                  <div className={styles['overlay']} />
                  {/*<div className={styles['image-wrapper']} style={renderBackgroundImage(article.hero_src)} />*/}
                </div>
              )}
              <div className={styles['attribution-container']}>
                <span className={styles['author']}>{article.author}</span>
                <span className={styles['category']}>{article.category_name}</span>
                <span className={styles['date']}>{formatDate(article.date_published)}</span>
              </div>
              <div className={styles['content-container']}>
                <h3 dangerouslySetInnerHTML={{ __html: article.h1 }} />
                <p dangerouslySetInnerHTML={{ __html: article.blurb }} />
              </div>
              <div className={styles['read-more-trigger-container']}>
                <ArticlesReadMoreTrigger />
              </div>
            </Link>
          ))}
        </div>
        {showCta && (
          <div className={styles['cta-container']}>
            <Link href="/news" className="button">
              View All Articles
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleCardsWithImages;
