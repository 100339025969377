import Logo from 'svgs/redesign/logo.svg';
import LogoElectricians from 'svgs/redesign/logo-electricians.svg';

const Icon = ({ name = '' }) => {
  const getIcon = (key) => {
    switch (key) {
      case 'Electricians':
        return <LogoElectricians />;

      default:
        return <Logo />;
    }
  };

  return getIcon(name);
};

export default Icon;
