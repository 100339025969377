import Icon from 'svgs/redesign/left-arrow-decal.svg';

import * as styles from './index.module.scss';

const LeftArrowDecal = () => {
  return (
    <div className={styles['left-arrow-decal']}>
      <Icon />
    </div>
  );
};

export default LeftArrowDecal;
