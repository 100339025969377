import Arrow from 'svgs/redesign/arrow.svg';

import * as styles from './index.module.scss';

const ArticlesReadMoreTrigger = () => (
  <div className={styles['read-more-trigger']}>
    Read More <Arrow />
  </div>
);

export default ArticlesReadMoreTrigger;
