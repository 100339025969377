import * as styles from './index.module.scss';

import Link from 'next/link';
import classnames from 'classnames';
import Image from 'next/image';

import CategoryLogo from '@/components/CategoryLogo';
import { TrackedPhoneNumber } from '../TrackedPhoneNumber';

const Header = ({ type = '', category = {} }) => {
  return (
    <header className={classnames(styles['header'], type && styles[type], category.use_dark_header && styles['dark'], category.logo_color && styles[category.logo_color])}>
      <div className={`${styles['container']} container`}>
        <div className={styles['main-logo-container']}>
          <Link href="/" tabIndex={0} aria-label="Call A Pro Logo">
            <CategoryLogo name={category?.name} />
          </Link>
        </div>
        <div className={styles['actions-container']}>
          <div className={styles['cta-container']}>
            <div className={styles['cta']}>
              <div>Call For a Fast Quote</div>
              <TrackedPhoneNumber />
            </div>
            <div className={styles['image-container']}>
              <Image
                src="https://s3.amazonaws.com/callapro.com/person1.png"
                width={95}
                height={95}
                alt="Call For a Fast Quote"
                quality={80}
                placeholder="blur"
                blurDataURL="iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/C/HgAGgwJ/lK3Q6wAAAABJRU5ErkJggg=="
              />
              <div className={styles['dot']}></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
